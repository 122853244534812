<template>
  <div>
    <v-card>
      <v-card-text>
        <h3 class="headline">ແກ້ໄຂຂໍ້ມູນ Banner</h3>
        <v-container>
          <v-form ref="form" lazy-validation>

            <v-row>
              <v-col>
                <div class="field">
                  <div class="file is-large is-boxed">
                    <label class="file-label">
                      <input
                          @change="onFileChange"
                          class="file-input input-file-image"
                          type="file"
                          name="image"
                          accept="image/*"
                          ref="image"
                          hidden
                      />
                      <span class="file-cta">
                                                  <span class="file-icon">
                                                    <v-icon
                                                        style="
                                                        font-size: 60px !important;
                                                        color: #719aff;
                                                        cursor: pointer;
                                                      "
                                                        class="fas fa-cloud-upload"
                                                    >mdi-cloud-upload</v-icon
                                                    >
                                                  </span>
                                                  <span
                                                      class="file-label"
                                                      style="
                                                      margin-top: 10px;
                                                      text-transform: uppercase;
                                                      padding-top: 20px;
                                                    "
                                                  >
                                                    Choose Profile
                                                  </span>
                                                </span>
                    </label>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="errors">
                  {{ server_errors.image }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-5" v-if="image">
                <v-avatar class="avatar rounded" size="94px">
                  <img :src="imageUrl" alt=""/>
                </v-avatar>
              </v-col>
              <v-col class="mt-5" v-else>
                <v-avatar
                    class="avatar rounded mr-6"
                    size="94px"
                >
                  <img v-if="banner.image" :src=" `${baseRoot}images/${banner.image}`"/>
                </v-avatar>
              </v-col>

            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Title"
                    v-model="banner.title"
                    outlined
                    dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Link"
                    v-model="banner.link"
                    outlined
                    dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Description"
                    v-model="banner.description"
                    outlined
                    dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols>
                <v-text-field
                    label="ຈັດລຽນລຳດັບ"
                    v-model="banner.priority"
                    type="number"
                    outlined
                    dense
                ></v-text-field>
                <p class="errors">
                  {{ server_errors.priority }}
                </p>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="Close()">
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="Update()"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      banner: {},
      image: "",
      imageUrl: "",
      server_errors: {
        title: '', priority: '', image: ''
      },
    }
  },

  methods: {
    onFileChange(e) {
      let input = e.target;
      let file = e.target.files[0];
      this.image = input.files[0];
      this.imageUrl = URL.createObjectURL(file);
      this.server_errors.imageFile = '';
      let formData = new FormData();
      formData.append("imageFile", this.image);
      this.$axios.post('/upload-image', formData)
          .then(res => {
            if (res.status == 200) {
              this.image = res.data.fileName;
              (this.image)
            }
          }).catch(error => {
        this.image = '';
        if (error.response.status == 422) {
          const obj = error.response.data.errors;
          for (let [key, user] of Object.entries(obj)) {
            this.server_errors[key] = user[0];
          }
        }
      });
    },
    fetchDetail() {
      this.loading = true;
      this.$admin.get('banner/' + this.$route.params.id, {})
          .then(res => {
            setTimeout(() => {
              this.loading = false;
              this.banner = res.data.data;
            }, 300);
          }).catch(error => {
        this.loading = false;
        this.$store.commit("Toast_State", this.toast_error);
        if (error.response.status == 422) {
          const obj = error.response.data.errors;
          for (let [key, truck_user] of Object.entries(obj)) {
            this.server_errors[key] = truck_user[0];
          }
        }
      });
    },
    Update() {
      let formData = new FormData();
      formData.append("title", this.banner.title);
      formData.append("description", this.banner.description);
      if (this.image != null && this.image != '') {
        formData.append("image", this.image);
      }
      if (this.image == null || '') {
        formData.append("image", this.news.image);
      }
      formData.append("priority", this.banner.priority);
      formData.append("link", this.banner.link);
      formData.append("_method", "PUT");
      if (this.$refs.form.validate() == true) {
        this.loading = true;
        this.$admin.post('banner/' + this.$route.params.id, formData, {
          headers: {"Content-Type": "multipart/form-data"}
        })
            .then(res => {
              if (res.status == 200) {
                setTimeout(() => {
                  this.loading = false;
                  this.Close();
                  this.news = {};
                }, 300);
              }
            }).catch(error => {
          this.loading = false;
          this.$store.commit("Toast_State", this.toast_error);
          if (error.response.status == 422) {
            const obj = error.response.data.errors;
            for (let [key, truck_user] of Object.entries(obj)) {
              this.server_errors[key] = truck_user[0];
            }
          }
        });
      }
    },
    Close() {
      this.$router.go(-1);
    },
  },
  watch:{
    'banner.priority':function (){
      this.server_errors.priority = '';
    },
    'image':function (){
      this.server_errors.image = '';
    }
  },
  created() {
    this.fetchDetail();
  }
};

</script>

<style>
.primary-color {
  color: #00c1d2;
}

@import "../../../../public/scss/main.scss";
</style>